import request from "@/base/util/request.js";
// 
// 删除
export const deletePlantoreturnInfoById = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolPlantoreturnInfo/deletePlantoreturnInfoById',
        method: 'get',
        params
    })
}

// GET 
//分页查询 
export const queryPlantoreturnInfoByPage = (data) => {
        return request({
            url: 'epidemicControl/epidemiccontrolPlantoreturnInfo/queryPlantoreturnInfoByPage',
            method: 'post',
            data
        })
    }
    //数据导入
export const importPlantoreturnInfosPath = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolPlantoreturnInfo/importPlantoreturnInfosPath',
        method: 'get',
        params
    })
}