<template>
  <div class="user-management">
    <el-row class="ctrArea">
      <el-col :span="2" :offset="18">
        <el-link
          type="primary"
          class="muban_download"
          download="在外及拟返乡人员排查表.xlsx"
          href="/epidemicControl/在外及拟返乡人员排查表.xlsx"
          >下载模板</el-link
        >
      </el-col>
      <el-col :span="2">
        <el-upload
          action=""
          :before-upload="beforeUpload"
          :limit="1"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :show-file-list="false"
          ref="batchImportUploadBtn"
          :disabled="batchImportUploadIng"
          :http-request="fileuploadHandle"
        >
          <el-button
            type="primary"
            size="mini"
            :disabled="batchImportUploadIng"
            :loading="batchImportUploadIng"
            >批量导入拟返乡人员信息</el-button
          >
        </el-upload>
      </el-col>
    </el-row>

    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form :inline="true" :model="listQuery" ref="departmentInfo">
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.name"
            placeholder="姓名"
            maxlength="32"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.contact"
            placeholder="请输入电话"
            maxlength="11"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.idNumber"
            placeholder="证件号"
            maxlength="32"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="searchBtn">搜索</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <epidemiccontrolPlantoreturnInfoTable
      :tableData="tableData"
      :showControl="true"
      :localtableHeight="tableHeight"
       @deleteSuccess="getTableData"
    ></epidemiccontrolPlantoreturnInfoTable>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :pageNum-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryPlantoreturnInfoByPage,
  deletePlantoreturnInfoById,
  importPlantoreturnInfosPath,
} from "../../../api/epidemiccontrolPlantoreturnInfo/index";

import { fileupload } from "../../../api/uploadfile/index";

import epidemiccontrolPlantoreturnInfoTable from "./components/epidemiccontrolPlantoreturnInfoTable.vue";
export default {
  components: {
    epidemiccontrolPlantoreturnInfoTable,
  },
  data() {
    return {
      batchImportUploadIng: false, //正在处理批量上传
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        name: "", //用户名
        contact: "", //电话
        idNumber: "", //证件号
      },
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getTableList(1);
  },

  filters: {},
  methods: {
    fileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            //告诉后台，文件已经上传成功以及文件信息，准备批量处理
            let paramers = {
              path: data.nginxFileReadPath,
            };
            importPlantoreturnInfosPath(paramers)
              .then((res) => {
                this.batchImportUploadIng = false;

                if (res.code == 1) {
                  this.$message.success(res.msg);
                  //重新查询数据
                  this.getTableData();
                } else if (res.code == 1001) {
                  this.$message.error("数据操作错误");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                this.batchImportUploadIng = false;
                // this.$message.error(e);
              });
          } else if (res.code == -1) {
            this.batchImportUploadIng = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.batchImportUploadIng = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.batchImportUploadIng = false;
        });
    },
    beforeUpload(file) {
      this.batchImportUploadIng = true;
    },
    //列表查询
    getTableList(val) {
      this.listQuery.pageNum = val;
      this.getTableData();
    },
    getTableData() {
      queryPlantoreturnInfoByPage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetBtn() {
      this.listQuery.pageNum = 1;
      this.listQuery.sampleNumber = "";
      this.listQuery.contact = "";
      this.listQuery.idNumber = "";
      this.getTableList(1);
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pages = 1;
      this.getTableList(1);
    },

   
  },
};
</script>

<style lang="less" scoped>
.user-management {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
  .mt20r {
    margin-top: 20px;
  }
}
</style>