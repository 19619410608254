<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    stripe
    :height="localtableHeight"
    border
  >
    <el-table-column type="index" label="序号" width="65"> </el-table-column>

    <el-table-column
      prop="township"
      label="乡镇、村（小区）"
      width="90"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column prop="name" show-overflow-tooltip label="姓名">
    </el-table-column>
    <el-table-column prop="gender" show-overflow-tooltip label="性别">
    </el-table-column>
    <el-table-column
      prop="idNumber"
      label="身份证号"
      show-overflow-tooltip
      width="190"
    >
    </el-table-column>
    <el-table-column prop="professional" label="职业"> </el-table-column>
    <el-table-column prop="contact" label="联系电话"> </el-table-column>
    <el-table-column prop="province" label="省"> </el-table-column>
    <el-table-column prop="city" label="市"> </el-table-column>
    <el-table-column prop="county" label="县"> </el-table-column>
    <el-table-column prop="vaccinationStatus" label="疫苗接种情况">
    </el-table-column>
    <el-table-column prop="outOfTownBusiness" label="在外地事由">
    </el-table-column>
    <el-table-column prop="noRecoveryPlan" label="暂无回成计划">
    </el-table-column>
    <el-table-column prop="pseudoReturnTime" label="拟回成时间">
    </el-table-column>
    <el-table-column prop="pseudoReturnSite" label="拟回成地点">
    </el-table-column>
    <el-table-column prop="pseudoReturnWay" label="拟回成方式">
    </el-table-column>
    <el-table-column prop="note" label="备注"> </el-table-column>

    <el-table-column
      label="操作"
      fixed="right"
      v-slot="scope"
      width="80"
      v-if="showControl"
    >
      <div class="option-list-box">
        <el-link
          :underline="false"
          type="danger"
          class="ml10r"
          @click="deleteOption(scope.row)"
          >删除</el-link
        >
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  deletePlantoreturnInfoById
} from "../../../../api/epidemiccontrolPlantoreturnInfo/index";
export default {
  props: {
    tableData: [],
    showControl: false, //隐藏操作区
    localtableHeight: "",
  },
  methods:{
     //删除
    deleteOption(row) {
      this.$confirm("确定要删除此数据", "数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          deletePlantoreturnInfoById(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
             this.$emit("deleteSuccess")
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  }
};
</script>

<style>
</style>